import { CircularProgress } from '@mui/material';
import React from 'react';

const Loader = () => {
  // Style for the container that holds the loader
  const loaderContainerStyle = {
    position: 'fixed',       
    top: 0,                  
    left: 0,                 
    width: '100%',           
    height: '100%',          
    background: 'rgba(0, 0, 0, 0.5)',  // Reduced opacity (0.5) black background
    display: 'flex',         
    justifyContent: 'center', 
    alignItems: 'center',     
    zIndex: 9999,            
  };

  // Style for the loader (the spinning circle)
  const loaderStyle = {
    border: '16px solid #f3f3f3',  
    borderTop: '16px solid #3498db', 
    borderRadius: '50%',          
    width: '80px',                
    height: '80px',
    animation: 'spin 1s linear infinite', 
  };

  return (
    <div style={loaderContainerStyle}>
     <CircularProgress sx={{color:'rgb(253, 126, 20)'}}  />

    </div>
  );
};

export default Loader;
