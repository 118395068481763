import { Box, Button } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

export default  function FileUploadComponent({Api,ID,CourseData}) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', file); // Assuming your API expects the file in the 'file' key
    formData.append('ProductID', ID);

    try {
      const response = await axios.post(Api, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Success:', response.data);
      CourseData();
    } catch (error) {
      console.error('Error:', error.response.data);
      CourseData()
    }
  };

  return (
    <form onSubmit={handleSubmit}>
        <Box display={'flex'} alignItems={'center'}>
        <input style={{border:'1px solid #ccc',width:'100%',borderRadius:5,padding:3}}  type="file" onChange={handleFileChange} />
      <Button variant='contained' size='small' type="submit" sx={{marginLeft:8}}>Upload</Button>
        </Box>
    </form>
  );
}
