
import React, { useState } from 'react';
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
  Container,
} from '@mui/material';

const Question = ({ questionData, onNextQuestion }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleNext = () => {
    const isCorrect = selectedOption === questionData.correctAnswer;
    onNextQuestion(isCorrect);
    setSelectedOption(null);
  };

  return (
    <Container sx={{padding:10}}>
      
      <Typography variant="h6">{questionData.question} ?</Typography>
      <FormControl fullWidth component="fieldset">
        <RadioGroup
          value={selectedOption}
          onChange={handleOptionChange}
        >
          {Object.entries(questionData.options).map(([key, value]) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={value}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
      >
        Next
      </Button>
    </Container>
  );
};

export default Question;
