import React from 'react'
import { Navigate, Outlet } from 'react-router';
import AdminLogin from './Component/Admin/AdminLogin';
const useAuth=()=>{


    const user={LoggedIn:localStorage.getItem("AdminLogin")};
    return user && user.LoggedIn;
}



export const ProteactedRoutes = () => {
    const isAuth=useAuth();
  return isAuth?<Outlet></Outlet>:<Navigate to={'admin/login'}></Navigate>
}
