
import React, { useState } from 'react';
import Question from './Question';

const Quiz = ({ data }) => {


  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  const handleNextQuestion = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }

    setCurrentQuestion(currentQuestion + 1);
  };

  return (
    <div>
      {currentQuestion < data.questions.length ? (
        <Question
          questionData={data.questions[currentQuestion]}
          onNextQuestion={handleNextQuestion}
        />
      ) : (
        <div>
          <h2>Quiz Completed!</h2>
          <p>Your Score: {score} / {data.questions.length}</p>
        </div>
      )}
    </div>
  );
};

export default Quiz;
