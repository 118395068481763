import React from 'react';

function Comment({ logo, text, author }) {
  // Inline styles for demonstration purposes
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      
    },
    logo: {
        border:"1px solid",
      width: '40px',
      height: '40px',
      borderRadius: '20px', // Makes the image round
      marginRight: '10px',
    },
    text: {
      fontSize: '14px',
    },
    author: {
      fontWeight: 'bold',
    }
  };

  return (
    <div style={styles.container}>
      <img  src={"https://www.aroundme.co.in/images/logo-min.webp"}  alt="logo" style={styles.logo} />
      <div>
        <div style={styles.author}>{author}User</div>
        <div style={styles.text}>{text}</div>
      </div>
    </div>
  );
}

export default Comment;
