import React from 'react'
import Layout from './Layout'
import Chart from './Chart'

const TestAdmin = () => {
  return (
<Layout Title={"Dashnoard"}>
    
    
</Layout>
  )
}

export default TestAdmin