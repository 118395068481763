import Carousel from 'react-bootstrap/Carousel';

function SliderHome() {
  return (
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXwR459DcItyOL0cmNbDY-rxftLiXFHtd5VbQGi1rmxw&s"
          alt="First slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://wowslider.com/sliders/demo-7/data/images/amsterdam.jpg"
          alt="Second slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://wowslider.com/sliders/demo-7/data/images/seul.jpg"
          alt="Third slide"
        />
    
      </Carousel.Item>
    </Carousel>
  );
}

export default SliderHome;