import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import ScreenHeader from '../ScreenHeader'
import axios from 'axios';
import { Box, Button, Container, Grid, Grow, Typography, createStyles } from '@mui/material';
import Loader from '../System/Loader';


const Course = () => {


  const [data, setData] = useState(null);

  // useEffect hook to fetch data when the component mounts
  useEffect(() => {
    // Function to fetch data using Axios
    const fetchData = async () => {
      try {
        // Make a GET request to your API endpoint
        const response = await axios.get('https://api.adamyaacademy.org/api/Course');

        // Update the state with the fetched data
        setData(response.data);
      } catch (error) {
        // Handle errors, for example, log the error to the console
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // E
  return (
    <>



<Container >

    <Grid container spacing={1} p={1} pb={10}>

{!data&&<Loader/>}

          {data&&data.map(item => (
    <Grid  item  sm={6} md={4} xs={6}>

        <Box sx={{ boxShadow: 5,
         
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          p: 1,
          m: 1,
          borderRadius: 2,
          textAlign: 'center',
          fontSize: '0.875rem',
        }} 
          boxShadow={3}
          key={item.ID}>
         
          <img style={{maxWidth:'100%', height:'auto',alignSelf:'center',justifyContent:'center',flex:1}} src={'https://api.adamyaacademy.org/AppData/'+item.img} alt={item.Title}  />
         
         <Box>
          <Typography style={{textAlign:'center', fontSize:"0.9rem",overflowWrap:'break-word',fontWeight:"700",justifySelf:'center',alignSelf:'center',justifyContent:'center',justifyItems:'center',}}> {item.Exam}</Typography>
         <Button  variant='contained' size='small' sx={{textTransform:'capitalize',color:'#fff',padding:'1px 9px',}}>View Course</Button>
         </Box>
        </Box>

        </Grid>
      ))}
      </Grid>
  
      </Container>
  



    </>
  )
}

export default Course

