import React from 'react'
import HomeWhatapp from '../../Whatapp/HomeWhatapp'
import Layout from './Layout'

const BulkSms = () => {
  return (
    <Layout Title={"BulkSms"} ><HomeWhatapp ></HomeWhatapp></Layout>
  )
}

export default BulkSms