import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

import MenuIcon from '@mui/icons-material/Menu';
import TokenIcon from '@mui/icons-material/Token';

import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import { useNavigate } from 'react-router';
export default function SideNav() {


  
  const navigotor=useNavigate();
  const [state, setState] = React.useState({
    top: false,
    
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
   
      onKeyDown={toggleDrawer(anchor, false)}
    >
      
      
      <List>

          <ListItem key={"text"} disablePadding>
            <ListItemButton onClick={()=>navigotor("/app")}>
              <ListItemIcon>
               <DashboardCustomizeIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>
       {/* --------------- */}



       
       <ListItem key={"text"} disablePadding>
            <ListItemButton onClick={()=>navigotor("/app/contact")}>
              <ListItemIcon>
               <ConnectWithoutContactIcon />
              </ListItemIcon>
              <ListItemText primary={"Contact us"} />
            </ListItemButton>
          </ListItem>
       {/* --------------- */}




       <ListItem key={"text"} disablePadding>
            <ListItemButton onClick={()=>navigotor("/app/course")}>
              <ListItemIcon>
               <LocalLibraryIcon />
              </ListItemIcon>
              <ListItemText primary={"Course"} />
            </ListItemButton>
          </ListItem>
       {/* --------------- */}





       <ListItem key={"text"} disablePadding>
            <ListItemButton onClick={()=>navigotor("/app/mycourse")}>
              <ListItemIcon>
               <FolderSpecialIcon />
              </ListItemIcon>
              <ListItemText primary={"My Course"} />
            </ListItemButton>
          </ListItem>
       {/* --------------- */}


       
       <ListItem key={"text"} disablePadding>
            <ListItemButton onClick={()=>navigotor("/app/examlist")}>
              <ListItemIcon>
               <TokenIcon />
              </ListItemIcon>
              <ListItemText primary={"Online test"} />
            </ListItemButton>
          </ListItem>
       {/* --------------- */}

      
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          
                <Button onClick={toggleDrawer(anchor, true)} style={{color:'white'}}>
                    <MenuIcon />
                </Button>
          
          
          
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}