import { Route, Routes } from "react-router";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Home from "./Component/Home";
import Privacy_policy from "./Component/Privacy_policy";
import Index from "./Component/APP/Index";
import Profile from "./Component/APP/Assests/Profile";
import Course from "./Component/APP/Assests/Course";
import Library from "./Component/APP/Assests/Library";
import CourseList from "./Component/APP/Playstore/CourseList";
import Golive from "./Component/APP/Assests/Golive";
import MyCourse from "./Component/APP/MyCourse";
import ContactusApp from "./Component/APP/ContactusApp";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ExamList from "./Component/APP/ExamList";
import ComingSoon from "./ComingSoon";
import Admin_Course from "./Component/Admin/Admin_Course";
import { ThemeProvider, createTheme } from "@mui/material";
import TestAdmin from "./Component/Admin/TestAdmin";
import AdminLogin from "./Component/Admin/AdminLogin";

import BulkSms from "./Component/Admin/BulkSms";
import { ProteactedRoutes } from "./ProteactedRoutes";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(253, 126, 20)", // A shade of yellow
    },
    // You can also customize other colors here
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId="824590830230-nbq2h6fev0u34t9h90q8ihhnii2j5uff.apps.googleusercontent.com">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/Profile" element={<Profile />}></Route>
            <Route path="/Library" element={<Library />}></Route>
            <Route path="/Course" element={<Course />}></Route>

            <Route path="/app/examlist" element={<ExamList />}></Route>

            <Route path="/app" element={<Index />}></Route>

            {/* <Route path='/app' element={<ComingSoon/>}></Route> */}

            <Route element={<ProteactedRoutes></ProteactedRoutes>}>
                  <Route path="/Admin/Course" element={<Admin_Course />}></Route>
                  <Route path="/Admin/dashboard" element={<TestAdmin />}></Route>
                  <Route path="/Admin/BulkSms" element={<BulkSms />}></Route>
            </Route>

            <Route path="/Admin/login" element={<AdminLogin />}></Route>

            <Route path="/Golive" element={<Golive />}></Route>
            <Route path="/app/course" element={<CourseList />}></Route>
            <Route path="/app/contact" element={<ContactusApp />}></Route>

            <Route path="/PrivacyPolicy" element={<Privacy_policy />}></Route>
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
