import React from 'react'
import Quiz from './Exam/Quiz'
import Header from './Header'
import { Container } from '@mui/material'

const ExamList = () => {

    const jsondata={
    "questions": [
      {
        "id": 1,
        "question": "“ ईश्वरचंद ने पत्र पढ़ा ” वाक्य में काल हैं ?",
        "options": {
          "A": "सामान्य भूत",
          "B": "पूर्ण भूत",
          "C": "अपूर्ण भूत",
          "D": "सामान्य भविष्य"
        },
        "correctAnswer": "A"
      },
      {
        "id": 2,
        "question": "गलत युग्म छाँटिए –",
        "options": {
          "A": "प्राचार्य – प्राचार्या",
          "B": "परोपकार – परोपकरा",
          "C": "सदाचार – सदाचारिणी",
          "D": "अध्यापक – अध्यापिका"
        },
        "correctAnswer": "B"
      },
      {
        "id": 3,
        "question": "वचन के संदर्भ में गलत पद बताइये –",
        "options": {
          "A": "समितियाँ",
          "B": "चुनावों",
          "C": "गुणों",
          "D": "नियुक्ति"
        },
        "correctAnswer": "D"
      },
      {
        "id": 4,
        "question": "‘ सदाचार ’ और ‘ पंडित ’ का स्त्रीलिंग बनेगा",
        "options": {
          "A": "सदाचारी – पंडिती",
          "B": "सदाचारिणी – पण्डिताइन",
          "C": "सदाचारा – पण्डितनी",
          "D": "सदाचारिणी – पंडिती"
        },
        "correctAnswer": "B"
      },
      {
        "id": 5,
        "question": "“ ईश्वरचंद विद्यासागर संस्कृत के पंडित रहे होंगे ” वाक्य को संदिग्ध – भविष्यकाल में बदलने पर वाक्य बनेगा –",
        "options": {
          "A": "ईश्वरचंद विद्यासागर संस्कृत के पंडित थे ।",
          "B": "ईश्वरचंद विद्यासागर संस्कृत के पंडित रहे होंगे ।",
          "C": "ईश्वरचंद विद्यासागर संस्कृत के पंडित जरूर होंगे ।",
          "D": "ईश्वरचंद विद्यासागर संस्कृत के पंडित हों ।"
        },
        "correctAnswer": "A"
      },
      {
        "id": 6,
        "question": "“ जिसका निवारण न किया जा सके ” वाक्यांश के लिए सार्थक शब्द हैं",
        "options": {
          "A": "अनिर्णित",
          "B": "निवारणहीन",
          "C": "अनिवार्य",
          "D": "औपचारिक"
        },
        "correctAnswer": "C"
      },
      {
        "id": 7,
        "question": "निम्नलिखित शब्दों में से शुद्ध हैं ?",
        "options": {
          "A": "अधिशासी",
          "B": "अल्येष्ठि",
          "C": "अतिथि",
          "D": "अणू"
        },
        "correctAnswer": "C"
      },
      {
        "id": 8,
        "question": "( :- ) कोष्ठक में दिया गया चिह्न हैं",
        "options": {
          "A": "योजक चिह्न",
          "B": "निर्देशक चिह्न",
          "C": "विवरण चिह्न",
          "D": "लाधव चिह्न"
        },
        "correctAnswer": "B"
      },
      {
        "id": 9,
        "question": "“ आँख का अंधा नाम का नयनसुख ” लोकोक्ति का अर्थ हैं",
        "options": {
          "A": "गुणों के विपरीत नाम",
          "B": "गुणों के अनुकूल नाम",
          "C": "गुणों के समान नाम",
          "D": "गुणों का नाम"
        },
        "correctAnswer": "A"
      },
      {
        "id": 10,
        "question": "“ दुर्गम ” शब्द का वाक्यांश होना ।",
        "options": {
          "A": "दूरगामी रास्ता",
          "B": "दूर करने वाला रास्ता",
          "C": "वह मार्ग जो कठिन होना",
          "D": "वह मार्ग जो सरल हो"
        },
        "correctAnswer": "A"
      },]}
  return (<>

    <Header></Header>
   
    <Container sx={{padding:3}}>
 <Quiz data={jsondata}></Quiz> 
 </Container>
 </>
  )
}

export default ExamList