import React, { useState } from "react";
import TtyIcon from "@mui/icons-material/Tty";
import { Button } from "@mui/material";
import Comment from "./Comment";
function ChatApp() {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");

  const sendMessage = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page
   if( inputMessage.length>=3){
if (inputMessage.trim()) {
      setMessages([...messages, inputMessage]);
      setInputMessage(""); // Clear input after sending
    }
  };
  }
  // Inline styles
  const styles = {
    chatContainer: {
    


      display: "flex",
      flexDirection: "column",
padding:10,
      Width: "100%",
      margin: "auto",
      borderRadius: "5px",
      overflow: "hidden",
   maxhight:10,
   position: "cover",
   left: 0,
   bottom: 0,

    },
    messageList: {
      
      flexGrow: 1,
      overflowY: "auto",
      padding: "10px",
      listStyle: "none",
      maxhight:10,
    },
    messageForm: {
      display: "flex",
      padding: "10px",
      borderTop: "1px solid #ccc",
    },
    input: {
      flexGrow: 1,
      padding: "10px",
      marginRight: "0",
      border: "1px solid #ccc",

      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
    button: {
      padding: "10px 20px",
      border: "none",
      borderRadius:5,
      backgroundColor: "#007bff",
      color: "white",
      cursor: "pointer",
    },
    buttonHover: {
      // Note: Inline styles don't support pseudo-classes like :hover
      backgroundColor: "#0056b3",
    },
  };

  return (
    <div style={styles.chatContainer}>
      <ul style={styles.messageList}>
        {messages.map((msg, index) => (
          
<Comment key={msg.id} logo={msg.logo} text={msg} author={msg.author} />
    
        ))}
      </ul>
      <form style={styles.messageForm} onSubmit={sendMessage}>
        <input
          type="text"
          value={inputMessage}
          onChange={(e) =>  setInputMessage(e.target.value)}
          placeholder="Type a message..."
          style={styles.input}
        />

        <Button
          sx={{ borderTopRightRadius: "5px",
           borderBottomRightRadius: "5px",
           borderTopLeftRadius: 0,
           borderBottomLeftRadius: 0,
          textTransform:'capitalize',
        }}
          variant="outlined"
          type="submit"
        >
          {" "}
          send
        </Button>
      </form>
    </div>
  );
}

export default ChatApp;
