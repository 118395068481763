// Login.js
import React, { useState } from 'react';
import logo from './Logo.png';

const LoginLayout = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleLogin = () => {
    // Implement your login logic here
    if (username === '' || password === '') {
      // If either username or password is empty, set isInvalid to true
      setIsInvalid(true);
    } else {
      // Otherwise, perform login logic
      setIsInvalid(false);
      console.log(`Logging in with username: ${username} and password: ${password}`);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.loginBox}>
        <img src={logo} alt="Logo.png" style={styles.logo} />

        <div style={styles.form}>
          <input
            type="text"
            placeholder="Username or Email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{ ...styles.input, border: isInvalid && username === '' ? '2px solid rgb(253, 126, 20)' : '2px solid #ccc' }}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ ...styles.input, border: isInvalid && password === '' ? '2px solid rgb(253, 126, 20)' : '2px solid #ccc' }}
          />
          <button onClick={handleLogin} style={styles.button}>
            Log In
          </button>
        </div>
        <div style={styles.orSeparator}>
          <span style={styles.line}></span>
          <span style={styles.orText}>OR</span>
          <span style={styles.line}></span>
        </div>
        <div style={styles.forgotPassword}>
Forgot Password?
        </div>
      </div>
    </div>
  );
};

const styles = {
  // ... (unchanged styles)

  input: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    // Default border style
    border: '1px solid #ccc',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  loginBox: {
    textAlign: 'center',
    maxWidth: '350px',
    width: '100%',
  },
  logo: {
    width: '200px',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px',
    backgroundColor: 'rgb(253, 126, 20)',
    color: '#fff',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  orSeparator: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  line: {
    flex: '1',
    borderTop: '1px solid #ccc',
  },
  orText: {
    padding: '0 10px',
    color: '#999',
    fontWeight: 'bold',
  },
  forgotPassword: {
    color:'rgb(253, 126, 20)',
    marginBottom: '20px',
  },
  signupLink: {
    marginTop: '20px',
  },
};

export default LoginLayout;
