import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const icon = {
  color:'#7b1fa2',
  fontWeight:'bold'
}

export default function Footer({Title}) {

    const navigotor=useNavigate();
  return (
    <React.Fragment>
      <CssBaseline />
     
      <AppBar position="fixed"  sx={{ top: 'auto', bottom: 0,backgroundColor:"rgb(253, 126, 20)",color:'white' }}>
        <Toolbar>
            <Stack style={{width:'100%'}}  direction={'row'} justifyContent={"space-between"}>

          <IconButton style={{display:'grid'}} sx={Title=='Home'&&icon} onClick={()=>{navigotor('/app')}} color="inherit" aria-label="open drawer">
            <Stack direction={'row'} justifyContent={'center'}><HomeOutlinedIcon/></Stack>
            <Typography fontSize={12} sx={Title=='Home'&&icon}>Home</Typography>
          </IconButton>

          <IconButton  style={{display:'grid',justifyContent:'center'}} sx={Title=='Course'&&icon} onClick={()=>{navigotor('/app/Course')}} color="inherit" aria-label="open drawer">
            <Stack direction={'row'} justifyContent={'center'}><GridViewOutlinedIcon /></Stack>
            <Typography fontSize={12} sx={Title=='Course'&&icon}>Course</Typography>
          </IconButton>

          
          <IconButton  style={{display:'grid',justifyContent:'center'}} sx={Title=='library'&&icon} onClick={()=>{navigotor('/library')}} color="inherit" aria-label="open drawer">
            <Stack direction={'row'} justifyContent={'center'}><SearchIcon /></Stack>
            <Typography fontSize={12} sx={Title=='library'&&icon}>Library</Typography>
          </IconButton>










          <IconButton style={{display:'grid'}}  sx={Title=='Profile'&&icon} onClick={()=>{ localStorage.getItem("Token")=="8107037979"? navigotor('/Profile'):navigotor('/Profile')}} color="inherit" aria-label="open drawer">
         

          {/* <IconButton style={{display:'grid'}}  sx={Title=='Profile'&&icon} onClick={()=>{ localStorage.getItem("Token")=="8107037979"? navigotor('/Profile'):navigotor('/Login')}} color="inherit" aria-label="open drawer"> */}
            <Stack direction={'row'} justifyContent={'center'}><AccountCircleOutlinedIcon /></Stack>
          
            <Typography fontSize={12}  sx={Title=='Profile'&&icon}>Profile</Typography>
          </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}