// BulkMessageForm.js
import React, { useState } from 'react';
import WhatsAppTextEditor from './WhatsAppTextEditor';
import ExcelReader from './ExcelReader';
import { TextField } from '@mui/material';

function BulkMessageForm({ sendMessage }) {
  const [recipients, setRecipients] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(recipients.split(','), message)
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Recipients (comma-separated):
        </label>
        <br></br>
        <TextField
          fullWidth
          value={recipients}
          onChange={(e) => setRecipients(e.target.value)}
          placeholder='Phone number'
        />
        <br></br>

      <label>

<br></br>
<ExcelReader setRecipients={setRecipients}></ExcelReader>
<br></br><br></br>
</label>



<WhatsAppTextEditor  value={message} setMessage={setMessage}></WhatsAppTextEditor>
     
      
    </form>
  );
}

export default BulkMessageForm;
