import React from 'react'

const ContactusHome = () => {
  return (
    <>
<div class="container contact-form" style={{height:'100vh',alignItems:'center',justifyContent:'center',width:"100%"}}>

<div  style={{flex:2,justifyContent:'flex-end',alignItems:'center lg-m-10' , padding: "20px"}}>
    
</div>
 <div  style={{flex:2,justifyContent:'flex-end',alignItems:'center lg-m-10' , padding: "20px"}}>
        <div class="col-md-6">
            <h2>Contact Us</h2>
            <form action="#" method="post">
                <div class="mb-3">
                    <label for="name" class="form-label">Your Name</label>
                    <input type="text" class="form-control" id="name" name="name" required/>
                </div>
                <div class="mb-3">
                    <label for="email" class="form-label">Your Email</label>
                    <input type="email" class="form-control" id="email" name="email" required/>
                </div>
                <div class="mb-3">
                    <label for="message" class="form-label">Your Message</label>
                    <textarea class="form-control" id="message" name="message" rows="5" required></textarea>
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
            </form>
        </div>
    </div>



  
</div>

    </>
  )
}

export default ContactusHome