import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import FileUploadComponent from './Course/FileUploadComponent';
const Admin_Course = () => {


const[CourseDataApi,setCourseDataApi]=useState([]);

    useEffect(() => {
      
    
      
        CourseData();
      
    }, [])
    
   const CourseData=()=>{


    // Import axios if you're using Node.js


// The URL of the API you want to call
const apiUrl = 'https://api.adamyaacademy.org/api/Course';

// Making a GET request using Axios
axios.get(apiUrl)
  .then(function (response) {
    // Handle success
  

   setCourseDataApi(response.data)
  })
  .catch(function (error) {
    // Handle error
    console.log(error);
  })
  .then(function () {
    // Always executed
  });

   }
    
   


  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Formet</TableCell>
            <TableCell>Exam</TableCell>
           
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {CourseDataApi&& CourseDataApi.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.ID}</TableCell>
              <TableCell>{row.Title}</TableCell>
              <TableCell>{row.Format}</TableCell>
              <TableCell>{row.Exam}</TableCell>
              <TableCell>{row.col5}
              
                <img height={60} src={'https://api.adamyaacademy.org/AppData/'+row.img}></img>
            <FileUploadComponent CourseData={CourseData} ID={1} Api={"https://api.adamyaacademy.org/api/file/update?ProductID="+row.ID}></FileUploadComponent>
              </TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Admin_Course