import React from 'react'
import Header from './Header'

import YouTubePlaylist from '@codesweetly/react-youtube-playlist'
import { Button, Container, Grid } from '@mui/material'
import Footer from './Footer'

import VideoClass from './VideoClass'


const Index = () => {
  return (
   <>
   
   <Header title={"adamyaacademy"}></Header>
   
<Container sx={{padding:3}}>

<Grid container spacing={1} py={2}>
  <Grid item xs={6}>
  <Button size={"small"}  style={{ fontSize:12,fontWeight:600,  textTransform:"capitalize"}} variant='outlined' fullWidth>Live classes</Button>   
  </Grid>
  
  <Grid item xs={6}>

 <Button size={"small"}  style={{ fontSize:12,fontWeight:600,  textTransform:"capitalize"}} variant='outlined' fullWidth>recorded classes</Button>
   </Grid>

   <Grid item xs={6}>
 <Button size={"small"}  style={{ fontSize:12,fontWeight:600,  textTransform:"capitalize"}} variant='outlined' fullWidth>Notes</Button>   
  </Grid>
  
  <Grid item xs={6}>

 <Button size={"small"}  style={{ fontSize:12,fontWeight:600,  textTransform:"capitalize"}} variant='outlined' fullWidth>Offline Class</Button>
   </Grid>


   <Grid item xs={6}>
 <Button size={"small"}  style={{ fontSize:12,fontWeight:600,  textTransform:"capitalize"}} variant='outlined' fullWidth>Test Series</Button>   
  </Grid>
  
  <Grid item xs={6}>

 <Button size={"small"}  style={{ fontSize:12,fontWeight:600,  textTransform:"capitalize"}} variant='outlined' fullWidth>Youtube</Button>
   </Grid>


</Grid>





<VideoClass></VideoClass>



     
</Container>

<Footer Title={"Home"}></Footer>

   </>
  )
}

export default Index