import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SideNav from './SideNav';
import {  useNavigate } from 'react-router-dom';


export default function ScreenHeader({Title}) {

    const navigate = useNavigate();

  // Function to go back
  const goBack = () => {
    navigate(-1)
  };
  return (
    <Box sx={{ flexGrow: 1 ,position:"absolute",width:'100%' }}>
      <AppBar position="static" sx={{backgroundColor:'rgb(253, 126, 20)'}}>
        <Toolbar>
            <Button sx={{color:'white'}} onClick={()=>goBack()}>
         <ArrowBackIcon/></Button>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1,paddingLeft:1 }}>
            {Title}
          </Typography>

        </Toolbar>
      </AppBar>
    </Box>
  );
}