import { AppBar, Button } from '@mui/material';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const ExcelReader = ({setRecipients}) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      readExcel(selectedFile);
    }
  };

  const readExcel = (file) => {
    const reader = new FileReader();

   

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Get data from Column A starting from the second row
      const columnAData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        blankrows: false,
        range: 'A2:A' + sheet['!ref'].split(':')[1].replace(/\D/g, ''),
      });

      // Filter out values that are not 12 digits long
      const filteredData = columnAData.filter((cellData) => /^\d{12}$/.test(cellData[0]));










      const abc= JSON.stringify(filteredData.map((cellData, index) => ( cellData[0])))










 let numbersArray = JSON.parse(abc.replace(/'/g, '"'));

 // Remove duplicates by converting the array to a Set
 numbersArray = Array.from(new Set(numbersArray));


 
 // Stringify the array back into a string
 const uniqueNumbersString = JSON.stringify(numbersArray);

 const stringWithoutBrackets = uniqueNumbersString.substring(1, uniqueNumbersString.length - 1);

 setRecipients(stringWithoutBrackets)
      setData(filteredData);
    };

    reader.readAsArrayBuffer(file);
  };



 const abc= JSON.stringify(data.map((cellData, index) => ( cellData[0])))










 let numbersArray = JSON.parse(abc.replace(/'/g, '"'));

 // Remove duplicates by converting the array to a Set
 numbersArray = Array.from(new Set(numbersArray));


 
 // Stringify the array back into a string
 const uniqueNumbersString = JSON.stringify(numbersArray);

 const stringWithoutBrackets = uniqueNumbersString.substring(1, uniqueNumbersString.length - 1);





  return (
    <div>
      <input type="file" accept=".xlsx" id="FileUp" style={{display:'none'}} onChange={handleFileChange} />
      <label for="FileUp" className='btn btn-warning' onChange={handleFileChange}>
      Upload Excel
      </label>


<AppBar></AppBar>
     
    </div>
  );
};

export default ExcelReader;