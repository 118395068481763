import React from 'react'
import Footer from '../Footer'
import ScreenHeader from '../ScreenHeader'

const Library = () => {
  return (
   <>
   <ScreenHeader Title={"Library"}></ScreenHeader>
   <Footer Title={"library"}></Footer>
   </>
  )
}

export default Library