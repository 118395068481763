import React from 'react'
import UdzSoftwareSolucation from './UdzSoftwareSolucation'

const Footer = () => {
  return (
    <>   
    
    <div style={{textAlign:'center',color:'white',backgroundColor:'black',padding:'20px'}}>
     <div >

Copyright © Adamyaacademy.org 2022 -2023 All rights reserved. 

    </div>

<div>
Website designed & developed BY:
<br></br>
<UdzSoftwareSolucation></UdzSoftwareSolucation>

</div>


</div>
</>

  )
}

export default Footer