import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import ScreenHeader from '../ScreenHeader'
import ProfileLayout from './ProfileLayout'
import Quiz from '../Exam/Quiz'
import { Box } from '@mui/material'

const Profile = () => {
  
  return (
   <>
    <ScreenHeader Title={"Profile"}></ScreenHeader>
   <Box py={10}>
   
      {/* <ProfileLayout></ProfileLayout> */}

  
</Box>


   <Footer Title={"Profile"}></Footer>
  
   </>
  )
}

export default Profile