import { Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function WhatsAppTextEditor({value,setMessage}) {
 
  const handleChange = (value) => {
    setMessage(value);
  };






















  

  



  return (
    <div className="whatsapp-text-editor">
     







      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={12} lg={7}>


<TextField value={value} multiline rows={5} placeholder='Text...' fullWidth onChange={(e)=>handleChange(e.target.value)}></TextField>
        
      

     
        </Grid>
        <Grid item xs={3} sm={2} lg={7}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
             // Disable button if message is empty
          >
            Send
          </Button>
        </Grid>
      </Grid>
   
    </div>
  );
}

export default WhatsAppTextEditor;






