import React from 'react'
import Footer from './Footer'
import Header from './Header'
import { Container, Paper, Typography } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import SendIcon from '@mui/icons-material/Send';
import TtyIcon from '@mui/icons-material/Tty';

const ContactusApp = () => {
  return (
   <>
   
   
   <Header title={"contact us"}></Header>
   
<Container sx={{padding:3}}>

 

<Typography variant='h5' fontWeight={'700'}>Our HelpLine (24x7)</Typography>

                <Paper elevation={3} sx={{padding:2,width:'100%' ,my:2}}> 
                <Typography variant='h6'  style={{ justifyContent:'start',display:'flex',padding:10}}>

                
                <TtyIcon sx={{height:30,width:30,color:'#4285F4',marginRight:1}}></TtyIcon>
             9413078774
</Typography>
</Paper>

                <Paper elevation={3} sx={{padding:2,width:'100%' ,my:2}}> 
                <Typography variant='h6' style={{ justifyContent:'start',display:'flex',padding:10}}>

                
             <MailOutlineIcon sx={{height:30,width:30,color:'#C5221F',marginRight:1}}></MailOutlineIcon>
info@adamyaacademy.org
</Typography>
</Paper>


<Paper elevation={3} sx={{padding:2,width:'100%' ,my:2}}> 
<Typography variant='h6'  style={{ justifyContent:'start',display:'inline-flex',padding:10}}>  
<FacebookIcon sx={{height:30,width:30,color:'#4867AA',marginRight:1}}></FacebookIcon>
FaceBook
</Typography>
</Paper>


<Paper elevation={3} sx={{padding:2,width:'100%' ,my:2}}>   
<Typography variant='h6'  style={{ justifyContent:'start',display:'inline-flex',padding:10}}>  
<InstagramIcon sx={{height:30,width:30,color:'#C905DF',marginRight:1}}></InstagramIcon> Instagram 
</Typography>
</Paper>


<div>
<Paper elevation={3} sx={{padding:2,width:'100%' ,my:2}}> 
<Typography variant='h6'  style={{ justifyContent:'start',display:'inline-flex',padding:10}}>  
<SendIcon sx={{height:30,width:30,color:'#279DD9',marginRight:1}}/> Telegram
</Typography>
</Paper>
</div>

</Container>
<Footer></Footer>
   </>
  )
}

export default ContactusApp