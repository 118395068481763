// QRCodeDisplay.js
import { Button } from '@mui/material';
import React from 'react';

function QRCodeDisplay({ qrCodeData }) {
    function DangerousHtmlComponent({htmlContent}) {
        return <div dangerouslySetInnerHTML={{__html: htmlContent}} />;
      }
  return (
    <div>
      {/* <img src={qrCodeData} alt="QR Code" /> */}
     


        <div style={{ 
          margin: "0 auto",

        }}>
{qrCodeData!=="QR Code not available. Please wait and refresh."&&<>
<DangerousHtmlComponent htmlContent={qrCodeData} />

</>
}


{qrCodeData==="QR Code not available. Please wait and refresh."&&<>

<Button>dsdsd</Button>

</>
}

        </div>

   
    </div>
  );
}

export default QRCodeDisplay;
