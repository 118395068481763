import React from 'react'
import Footer from './Footer'
import { Box, Container } from '@mui/material'
import GoogleMaps_emb from './GoogleMaps_emb'
import { Info } from './Info'
import Course from './APP/Assests/Course'
import WebHeader from './Web/WebHeader'
import SliderDemo from './Reuse/SliderDemo'
import YouTubePlaylist from '@codesweetly/react-youtube-playlist'

import { GoogleLogin } from '@react-oauth/google'
import ReactFacebookLogin from 'react-facebook-login'
import SliderHome from './Web/SliderHome'
import FooterHome from './Web/FooterHome'
import ContactusHome from './Web/ContactusHome'

const Home = () => {
  const responseFacebook = (response) => {
    console.log(response);
  }
  return (
    <div>

{/* <WebHeader/> */}
<WebHeader></WebHeader>
<SliderHome></SliderHome>
<ContactusHome></ContactusHome>
<FooterHome></FooterHome>





{/* <SliderDemo></SliderDemo> */}
{/* <Course></Course> */}

{/* <Slider></Slider> */}

<Box>

<Container>




</Container>
</Box>
</div>



      











 )
}

export default Home