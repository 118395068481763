import React, { useEffect, useState } from 'react'
import BulkMessageForm from './BulkMessageForm';
import QRCodeDisplay from './QRCodeDisplay';
import ExcelReader from './ExcelReader';
import WhatsAppTextEditor from './WhatsAppTextEditor';
import { Button, Typography } from '@mui/material';

const HomeWhatapp = () => {
    const [qrCodeData, setQRCodeData] = useState('');
    const [messageSent, setMessageSent] = useState('');
  
    const generateQRCode = async () => {
      try {
        const response = await fetch('https://whatsapp.udzsms.in/qr-code');
        const qrCodeData = await response.text();
        setQRCodeData(qrCodeData);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };
  
    const sendMessage = async (recipients, message) => {
      try {
        const response = await fetch('https://whatsapp.udzsms.in/send-messages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ recipients, message })
        });
        const data = await response.text();
        setMessageSent(data);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };


    const [Count,setCount]=useState(0)

   
// -------------------------------------------------------------------------------------------------

  return (
   <>
    <Typography variant='h5'>WhatsApp Bulk SMS Sender</Typography>
      <Button variant='contained' onClick={generateQRCode}>Generate QR Code</Button>
      <br></br>
      <br></br>

      <div style={{width:"100%" ,alignSelf:'center'}}>

      {qrCodeData && <QRCodeDisplay qrCodeData={qrCodeData} />}

      </div>



     



      <br></br>
      <br></br>   <br></br>




      <BulkMessageForm sendMessage={sendMessage} />
      {messageSent && <p>{messageSent}</p>}

   
   </>
  )
}

export default HomeWhatapp