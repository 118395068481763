import React from 'react'


const Golive = () => {
  return (
    <div>Golive
dfd




sds
    </div>
  )
}

export default Golive