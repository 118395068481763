import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import SideNav from './SideNav';
import LoginPop from './Assests/LoginPop';

export default function Header({title}) {
  return (
    <Box sx={{ flexGrow: 1  }}>
      <AppBar  position="static" sx={{backgroundColor:'rgb(253, 126, 20)'}}>
        <Toolbar>
            <SideNav>
            </SideNav>
 
          <Typography variant="h6"  component="div" sx={{ flexGrow: 1,textTransform:'capitalize',color:'white' }}>
          {title}
          </Typography>
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}