import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import Course from '../Assests/Course'

const CourseList = () => {
  return (
    <>
    <Header title={"Course"}></Header>
    <Course></Course>
    <Footer Title={"Course"}></Footer>
  </>
  )
}

export default CourseList